// Here you can add other styles

.react-datepicker-popper {
    z-index: 3 !important;
}

.order-input {
    margin-bottom: 10px;
    min-width: 162px !important;

    label {
        min-width: 140px;
    }
}

@media screen and (max-width: 1145px) { 
    .sum-inner {
        order: 4;
        min-width: 100%;
    }
    .card-body {
        table {
            td,tr,th {
                width: 100%;
                padding: 8px !important;
            }
            min-width: 270px;
        }
    }
}

@media screen and (max-width: 920px) { 
    .card-body {
        table {
            td,tr,th {
                padding: 8px !important;
            }
        }
    }
}

@media screen and (max-width: 800px) { 
    .css-1l9ohcm {
        width: 100%;
    }
 }