/* orders/ */
@import url('styles/orders.css');
@import url('styles/products.css');

/*================================================
input type="number" - щоб браузер не відображав стрілки додавання/віднімання
=================================================*/
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number],
input[type=number]:hover,
input[type=number]:focus {
  -webkit-appearance: none;
          appearance: none;
  -moz-appearance: textfield;
}
/* ============================================== */

.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.characters {
  list-style: none;
  padding-left: 0;
}

.characters li {
  display: flex;
  align-items: center;
  border: solid 2px #d0d0d0;
  border-radius: .2em;
  padding: .5em .8em .5em .5em;
  margin-bottom: 1em;
}

.characters p {
  max-width: none;
  font-weight: bold;
  margin: 0;
}

.characters-thumb {
  overflow: hidden;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  background-color: #e8e8e8;
  padding: .5em;
  margin-right: .5em;
}

.characters-thumb img {
  display: block;
  width: 100%;
  height: auto;
}

.SortPunkt{
  margin-left: 40px;
}

.kramar-bg, .sidebar{
  background: #299fd9 !important;
}

.sidebar a{
  color: #fff !important;
}

a {
  color: #0088E4 !important;
}

.accordion-body{
  background: #f8f9fa !important;
}

.myAllert {
  z-index: 9999999999;
  /* background-color: red; */
}

.header-brand .icon.icon-custom-size{
  display: none !important;
}

.jadro-btn {
  background-color: #0088E4;
  border-radius: 0 !important;
  font-weight: bold;
  color: #fff;
}

.jadro-btn:hover {
  background-color: #F4C700 !important;
  border: #F4C700 !important;
}
 
@media screen and (max-width: 767px) {
  .filter-field {
    margin-bottom: 10px;
  }
}
 
.accordion {
  --bs-accordion-active-color: #000 !important;
  --bs-accordion-active-bg: rgba(0, 0, 0, 0.03) !important;
  --bs-accordion-border-radius: 0 !important;
  --bs-accordion-btn-bg: rgba(0, 0, 0, 0.03) !important;
  /* --bs-accordion-btn-padding-x: 1.25rem; */
  /* --bs-accordion-btn-padding-x: 1.25rem; */
  /* --bs-accordion-btn-padding-y: 1rem; */
  --bs-accordion-btn-padding-y: 0.7rem !important;
}

.accordion-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.176) !important;
}


.accordion-body {
  background: #fff !important;
  padding: 0 !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.popover.bs-popover-end, .popover.bs-popover-top{
  z-index: 99999  !important;
}

.popover.bs-popover-top .popover-body{
  background-color: palegoldenrod;
}